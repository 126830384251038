import { queryCMS } from './Api.js'

global.cmsApi = {

    getCmsItem: function (code) {
        var items = global.cmsDefaultData.filter(item => item.code === code);
        if (items.length > 0) {
            return items[0];
        } else {
            return null;
        }
    },

    getCmsData: function (code, completion) {
        var cmsItem = this.getCmsItem(code)
        if (cmsItem == null) return;
        var local = localStorage.getItem(code);
        if (local) {
            var localData = JSON.parse(local);
            completion(localData);
        }
        queryCMS(cmsItem.query).then((data) => {
            try {
                completion(data);
                localStorage.setItem(code, JSON.stringify(data));
            } catch (error) { }
        }, (error) => { });
    },

    getHomeCms: function (completion) {
        var code = "CmsHome"
        this.getCmsData(code, completion);
    },

    getPageCms: function (pageId, completion) {
        var query = `
        queryPaginaContents(filter: "data/CodigoPagina/iv eq '${pageId}'") {
            flatData {
                secciones {
                  ... on TextoComponent {
                    tipo
                    contenido
                  }
                  ... on BannersComponent {
                    tipo
                    titulo
                    mostrarTitulo
                    elementos {
                      titulo
                      imagen {
                        url
                      }
                      url
                    }
                  }
                }
              }
            }
        `
        queryCMS(query).then((data) => {
            try {
                completion(data);
                console.log("Cloud")
            } catch (error) {
                //loadDefaultImages();
            }
        }, (error) => {
            //loadDefaultImages();
        });
    },

}

global.cmsDefaultData = [
    {
        code: "CmsHome",
        query:
            `
            querySeccionInicioContents {
                flatData {
                    titulo
                    contenido {
                        text
                    }
                    imagenDerecha {
                        url
                    }
                }
            }
            querySeccionServiciosContents {
                flatData {
                    titulo
                    descripcion
                    servicios {
                        titulo
                        url
                        descripcion
                        icono {
                            url
                        }
                    }
                }
            }
            querySeccionTestimoniosContents {
                flatData {
                    titulo
                    descripcion
                    testimonios {
                        cliente
                        descripcion
                        imagen {
                            url
                        }
                    }
                }
            }
            querySeccionClientesContents {
                flatData {
                    titulo
                    descripcion
                }
            }
            querySeccionNosotrosContents {
                flatData {
                    titulo
                    descripcion
                    contenido {
                        text
                    }
                }
            }
        `,
        data: null,
    },
]